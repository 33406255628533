<script>
  import { project, orgOperators } from "$lib/services/store";
  import { translations } from "$lib/data/translations";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import SunEditorForm from "$lib/admin/components/content/SunEditorForm.svelte";
  import Intro from "$lib/admin/components/blocks/sections/Intro.svelte";
  import Bonus from "$lib/admin/components/blocks/blocks/Bonus.svelte";
  import Rating from "$lib/admin/components/blocks/helpers/Rating.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";
  import ArticleAd from "$lib/admin/components/blocks/helpers/ArticleAd.svelte";
  import FocusPanel from "$lib/admin/components/blocks/blocks/FocusPanel.svelte";
  import Toplist from "$lib/admin/components/blocks/blocks/Toplist.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import CurrencyAmount from "$lib/admin/components/blocks/helpers/CurrencyAmount.svelte";
  import { createEventDispatcher } from "svelte";

  export let props = null;
  export let adminView = false;

  let selectedTab = "facts";
  let reviewOperator = null;

  const dispatch = createEventDispatcher();
  const lang = $project.settings.lang ?? "en";

  props.bonusType = "single-table";
  props.showCTA = true;

  $: {
    reviewOperator = $orgOperators?.find((x) => x.id === props.operatorId);
    props.header = reviewOperator?.name;
    props.buttonAnchor =
      translations["To"][lang] + " " + reviewOperator?.name ?? "Casino Review";
    props.buttonUrl = `/${$project.settings.redirectUrl}/${reviewOperator?.name_lower}`;
    props.tocData = {
      tag: `${translations["Facts"][lang].toLowerCase()}`,
      title: `${translations["Facts about"][lang]} ${reviewOperator?.name}`,
    };
    if (
      reviewOperator &&
      (reviewOperator.rating[$project.key][$project.settings.market][
        "closed"
      ] ||
        !reviewOperator.rating[$project.key][$project.settings.market][
          "license"
        ])
    ) {
      props.showCTA = true;
      props.introCTAType = "internal";
      props.buttonType = "transparent";
      props.buttonAnchor = "Se Liknande Casinon";
      props.buttonUrl = "/stangt";
    }
    if (
      reviewOperator &&
      reviewOperator.rating[$project.key][$project.settings.market]["upcoming"]
    ) {
      props.showCTA = true;
      props.introCTAType = "internal";
      props.buttonType = "transparent";
      props.buttonAnchor = "Se Liknande Casinon";
      props.buttonUrl = "/kommer-snart";
    }
  }
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="Operator"
      type="select"
      bind:value={props.operatorId}
      on:change={() => dispatch("keyup")}
    >
      <option value="">~not set~</option>
      {#each $orgOperators as operator}
        <option value={operator.id}
          >{operator.name || "Couldn't find operator name"}</option
        >
      {/each}
    </Input>
  </Group>
  <Group addClass="form-item">
    <SunEditorForm
      placeholder="Introduction"
      bind:text={props.content}
      on:keyup
    />
  </Group>
{:else}
  <Intro {props} {adminView} operator={reviewOperator} />
  <ArticleAd />
  {#if reviewOperator && (reviewOperator.rating[$project.key][$project.settings.market]["closed"] || !reviewOperator.rating[$project.key][$project.settings.market]["license"])}
    <FocusPanel
      props={{
        focusPanelType: "basic",
        focusPanelContent:
          "<p>Detta casino har stängt eller är inte längre verksamma i Sverige. Kolla in vår <a href='/svenska-casinon#listan'>lista över svenska casinon</a> istället.</p>",
        focusPanelBasicType: "warning",
      }}
    />
    <Toplist
      props={{
        toplistId: 9,
        headingSize: "h3",
        headingText: "Rekommenderade casinon",
        toplistType: "table",
        toplistFocus: "casino",
        toplistLength: 5,
        toplistLinkUrl: "/svenska-casinon",
        toplistLinkAnchor: "Visa alla casinon",
        tocData: {
          tag: "listan",
        },
      }}
    />
  {:else if reviewOperator && reviewOperator.rating[$project.key][$project.settings.market]["upcoming"]}
    <FocusPanel
      props={{
        focusPanelType: "basic",
        focusPanelContent:
          "<p>Detta casino har inte öppnat dörrarna för svenska spelare än. Kolla in vår <a href='/svenska-casinon#listan'>lista över svenska casinon</a> istället.</p>",
        focusPanelBasicType: "warning",
      }}
    />
    <Toplist
      props={{
        toplistId: 9,
        headingSize: "h3",
        headingText: "Rekommenderade casinon",
        toplistType: "table",
        toplistFocus: "casino",
        toplistLength: 5,
        toplistLinkUrl: "/svenska-casinon",
        toplistLinkAnchor: "Visa alla casinon",
      }}
    />
  {:else}
    <Bonus {props} {adminView} dataElement="review-toplist" />
  {/if}
  <div id="facts" class="block block-tabbed-tables">
    <div class="container">
      <div class="grid">
        <div class="g-col-12">
          <div class="tab-section">
            <ul class="tabs">
              <li class:active={selectedTab === "facts"}>
                <button
                  class="icon"
                  on:click={() => {
                    selectedTab = "facts";
                  }}
                  ><Icons name="info" strokeWidth="2" />
                  <span
                    ><Translate text="Facts about" />
                    {reviewOperator?.name}</span
                  ></button
                >
              </li>
              <li class:active={selectedTab === "info"}>
                <button
                  class="icon"
                  on:click={() => {
                    selectedTab = "info";
                  }}
                  ><Icons name="more-horizontal" strokeWidth="2" />
                  <span><Translate text="More info" /></span></button
                >
              </li>
            </ul>

            {#if reviewOperator}
              <table class:show={selectedTab === "facts"}>
                <tbody>
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={translations["English license"][lang]}
                        src={`${$project.data.imageFolders.icons}${translations[
                          "English license"
                        ][lang]
                          .toLowerCase()
                          .replaceAll(" ", "-")}.png?auto=format`}
                      />
                      <b><Translate text="English license" /></b>
                    </td>
                    <td
                      >{reviewOperator?.countries.includes(
                        $project.settings.market,
                      )
                        ? translations["Yes"][lang]
                        : "No"}</td
                    >
                  </tr>
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={translations["Games offered"][lang]}
                        src={`${$project.data.imageFolders.icons}${translations[
                          "Games offered"
                        ][lang]
                          .toLowerCase()
                          .replaceAll(" ", "-")}.png?auto=format`}
                      />
                      <b><Translate text="Games offered" /></b>
                    </td>
                    <td>
                      {#if reviewOperator?.data.gameCategories}
                        {@const trueGameCategories = Object.entries(
                          reviewOperator.data.gameCategories,
                        )
                          .filter(([_, value]) => value)
                          .map(([key, _]) => translations[key][lang])
                          .join(", ")}
                        {trueGameCategories}
                      {:else}
                        -
                      {/if}
                    </td>
                  </tr>
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={translations["Number of games"][lang]}
                        src={`${$project.data.imageFolders.icons}${translations[
                          "Number of games"
                        ][lang]
                          .toLowerCase()
                          .replaceAll(" ", "-")}.png?auto=format`}
                      />
                      <b><Translate text="Number of games" /></b>
                    </td>
                    <td
                      >{reviewOperator?.data?.games?.[$project.settings.market]
                        ?.total_amount_games ?? "-"}</td
                    >
                  </tr>
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={translations["Support"][lang]}
                        src={`${$project.data.imageFolders.icons}${translations[
                          "Support"
                        ][lang]
                          .toLowerCase()
                          .replaceAll(" ", "-")}.png?auto=format`}
                      />
                      <b><Translate text="Support" /></b>
                    </td>
                    <td>
                      {#if reviewOperator?.data?.support?.[$project.settings.market]}
                        {@const supportOptions = Object.entries(
                          reviewOperator.data.support[$project.settings.market],
                        ).filter(([type, val]) => val != null)}
                        {#each supportOptions as [type, value], i}
                          {#if value}
                            {#if i > 0},
                            {/if}
                            {#if type === "email"}
                              <Translate text={"Email"} />
                            {:else if type === "live_chat"}
                              <Translate text={"Live chat"} />
                            {:else if type === "phone"}
                              <Translate text={"Phone"} />
                            {/if}
                          {/if}
                        {/each}
                      {:else}
                        -
                      {/if}
                    </td>
                  </tr>
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={translations["Tax free winnings"][lang]}
                        src={`${$project.data.imageFolders.icons}${translations[
                          "Tax free winnings"
                        ][lang]
                          .toLowerCase()
                          .replaceAll(" ", "-")}.png?auto=format`}
                      />
                      <b><Translate text="Tax free winnings" /></b>
                    </td>
                    <td
                      >{reviewOperator?.rating?.[$project.key][
                        $project.settings.market
                      ]?.prioToplist?.tax_free === "1"
                        ? translations["Yes"][lang]
                        : translations["No2"][lang]}</td
                    >
                  </tr>
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={`${$project.name} Rating`}
                        src={`${$project.data.imageFolders.assets}${$project.name_lower}-icon.png?auto=format`}
                      />
                      <b><Translate text="Our rating" /></b>
                    </td>
                    <td class="rating-cell"
                      ><Rating
                        operator={reviewOperator}
                        blackColor="true"
                      /></td
                    >
                  </tr>
                </tbody>
              </table>
              <table class:show={selectedTab === "info"}>
                <tbody>
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={translations["License"][lang]}
                        src={`${$project.data.imageFolders.icons}${translations[
                          "License"
                        ][lang]
                          .toLowerCase()
                          .replaceAll(" ", "-")}.png?auto=format`}
                      />
                      <b><Translate text="License" /></b>
                    </td>
                    <td>
                      {#if reviewOperator?.license_id}
                        {@const sharedLicenseOperators = $orgOperators.filter(
                          (obj) =>
                            obj.license_id &&
                            obj.license_id.id ===
                              reviewOperator?.license_id?.id &&
                            obj.id != reviewOperator.id &&
                            !obj.rating[$project.key]?.[
                              $project.settings.market
                            ]?.["closed"] &&
                            !obj.rating[$project.key]?.[
                              $project.settings.market
                            ]?.["hide"],
                        )}

                        {reviewOperator.license_id?.name}
                        <br />
                        {#if sharedLicenseOperators.length > 0 && !reviewOperator.rating[$project.key][$project.settings.market]["closed"]}
                          <Translate text="Shares license with" />:
                          {#each sharedLicenseOperators as operator, i}
                            {#if i > 0},
                            {/if}
                            <a
                              href={`/${$project.data.categoryPages.reviews.slug}/${operator.name_lower}`}
                              >{operator.name}</a
                            >
                          {/each}
                        {/if}
                      {:else}
                        <Translate text="No" />
                      {/if}
                    </td>
                  </tr>
                  <tr>
                    <td class="icon">
                      <img
                        loading="lazy"
                        alt={translations["Founded"][lang]}
                        src={`${$project.data.imageFolders.icons}${translations[
                          "Founded"
                        ][lang]
                          .toLowerCase()
                          .replaceAll(" ", "-")
                          .replaceAll("å", "a")}.png?auto=format`}
                      />
                      <b><Translate text="Founded" /></b>
                    </td>
                    <td>{reviewOperator?.founded ?? "-"}</td>
                  </tr>
                  {#if reviewOperator.data && reviewOperator.data.gameProviders && reviewOperator.data.gameProviders[$project.settings.market]}
                    <tr>
                      <td class="icon">
                        <img
                          loading="lazy"
                          alt={translations["Game providers"][lang]}
                          src={`${
                            $project.data.imageFolders.icons
                          }${translations["Game providers"][lang]
                            .toLowerCase()
                            .replaceAll(" ", "-")
                            .replaceAll("ö", "o")}.png?auto=format`}
                        />
                        <b><Translate text="Game providers" /></b>
                      </td>
                      <td>
                        {#if reviewOperator.data.gameProviders[$project.settings.market].length}
                          {#each reviewOperator.data.gameProviders?.[$project.settings.market].sort() as gameProvider, i}
                            {#if i > 1},
                            {/if}
                            {#if props.gameProviderLinks?.hasOwnProperty(gameProvider)}
                              <a href={props.gameProviderLinks?.[gameProvider]}
                                >{gameProvider}</a
                              >
                            {:else}
                              {gameProvider}
                            {/if}
                          {/each}
                        {:else}
                          -
                        {/if}
                      </td>
                    </tr>
                  {/if}
                  {#if reviewOperator.data && reviewOperator?.rating?.[$project.key][$project.settings.market] && reviewOperator?.rating?.[$project.key][$project.settings.market].prioToplist}
                    <tr>
                      <td class="icon">
                        <img
                          loading="lazy"
                          alt={translations["Mobile casino"][lang]}
                          src={`${
                            $project.data.imageFolders.icons
                          }${translations["Mobile casino"][lang]
                            .toLowerCase()
                            .replaceAll(" ", "-")}.png?auto=format`}
                        />
                        <b><Translate text="Mobile casino" /></b>
                      </td>
                      <td
                        >{reviewOperator?.rating?.[$project.key][
                          $project.settings.market
                        ].prioToplist.mobile_casino
                          ? translations["Yes"][lang]
                          : translations["No2"][lang]}</td
                      >
                    </tr>
                    <tr>
                      <td class="icon">
                        <img
                          loading="lazy"
                          alt={translations["Live casino"][lang]}
                          src={`${
                            $project.data.imageFolders.icons
                          }${translations["Live casino"][lang]
                            .toLowerCase()
                            .replaceAll(" ", "-")}.png?auto=format`}
                        />
                        <b><Translate text="Live casino" /></b>
                      </td>
                      <td
                        >{reviewOperator?.rating?.[$project.key][
                          $project.settings.market
                        ].prioToplist.live_casino
                          ? translations["Yes"][lang]
                          : translations["No2"][lang]}</td
                      >
                    </tr>
                    <tr>
                      <td class="icon">
                        <img
                          loading="lazy"
                          alt={translations["Fast registration"][lang]}
                          src={`${
                            $project.data.imageFolders.icons
                          }${translations["Fast registration"][lang]
                            .toLowerCase()
                            .replaceAll(" ", "-")}.png?auto=format`}
                        />
                        <b><Translate text="Fast registration" /></b>
                      </td>
                      <td
                        >{reviewOperator?.rating?.[$project.key][
                          $project.settings.market
                        ].prioToplist.paynplay
                          ? translations["Yes"][lang]
                          : translations["No2"][lang]}</td
                      >
                    </tr>
                    <tr>
                      <td class="icon">
                        <img
                          loading="lazy"
                          alt={translations["E-legitimation"][lang]}
                          src={`${
                            $project.data.imageFolders.icons
                          }${translations["E-legitimation"][lang]
                            .toLowerCase()
                            .replaceAll(" ", "-")}.png?auto=format`}
                        />
                        <b><Translate text="E-legitimation" /></b>
                      </td>
                      <td
                        >{reviewOperator?.rating?.[$project.key][
                          $project.settings.market
                        ].prioToplist.bankid
                          ? translations["Yes"][lang]
                          : translations["No2"][lang]}</td
                      >
                    </tr>
                  {/if}
                  {#if reviewOperator.data && reviewOperator.data.deposit}
                    <tr>
                      <td class="icon">
                        <img
                          loading="lazy"
                          alt={translations["Deposit options"][lang]}
                          src={`${
                            $project.data.imageFolders.icons
                          }${translations["Deposit options"][lang]
                            .toLowerCase()
                            .replaceAll(" ", "-")
                            .replaceAll("ä", "a")}.png?auto=format`}
                        />
                        <b><Translate text="Deposit options" /></b>
                      </td>
                      <td>
                        {#if props.depositMethodLinks && props.depositMethodLinks.length}
                          {#each props.depositMethodLinks as deposit, i}
                            {#if deposit.url != "undefined"}
                              <a
                                style="text-transform: capitalize;"
                                href={deposit.url}>{deposit.title}</a
                              >{props.depositMethodLinks.length > i + 1
                                ? ", "
                                : ""}
                            {:else}
                              {deposit.title +
                                (props.depositMethodLinks.length > i + 1
                                  ? ", "
                                  : "")}
                            {/if}
                          {/each}
                        {:else if reviewOperator.data.deposit[$project.settings.market] && reviewOperator.data.deposit[$project.settings.market].length}
                          {#each reviewOperator.data.deposit[$project.settings.market] as deposit, i}
                            {deposit +
                              (reviewOperator.data.deposit[
                                $project.settings.market
                              ].length >
                              i + 1
                                ? ", "
                                : "")}
                          {/each}
                        {:else}
                          <Translate text="No" />
                        {/if}
                      </td>
                    </tr>
                  {/if}
                  {#if reviewOperator.data && reviewOperator.data.depositLimits && reviewOperator.data.depositLimits.min && reviewOperator.data.depositLimits.min[$project.settings.market] && reviewOperator.data.depositLimits.min[$project.settings.market].min}
                    <tr>
                      <td class="icon">
                        <img
                          loading="lazy"
                          alt={translations["Minimum deposit"][lang]}
                          src={`${
                            $project.data.imageFolders.icons
                          }${translations["Minimum deposit"][lang]
                            .toLowerCase()
                            .replaceAll(" ", "-")
                            .replaceAll("ä", "a")}.png?auto=format`}
                        />
                        <b><Translate text="Minimum deposit" /></b>
                      </td>
                      <td
                        ><CurrencyAmount
                          amount={reviewOperator.data.depositLimits.min[
                            $project.settings.market
                          ]?.min}
                        /></td
                      >
                    </tr>
                  {/if}
                  {#if reviewOperator.data && reviewOperator["data"]["support"] && reviewOperator["data"]["support"][$project.settings.market]["email"]}
                    <tr>
                      <td class="icon">
                        <img
                          loading="lazy"
                          alt={translations["Email"][lang]}
                          src={`${
                            $project.data.imageFolders.icons
                          }${translations["Email"][lang]
                            .toLowerCase()
                            .replaceAll(" ", "-")}.png?auto=format`}
                        />
                        <b><Translate text="Email" /></b>
                      </td>
                      <td
                        ><a
                          href={`mailto:${
                            reviewOperator["data"]["support"][
                              $project.settings.market
                            ]["email"]
                          }`}><Translate text="Send email" /></a
                        ></td
                      >
                    </tr>
                  {/if}
                  {#if reviewOperator.data && reviewOperator.data.support && reviewOperator.data.support[$project.settings.market]?.phone}
                    <tr>
                      <td class="icon">
                        <img
                          loading="lazy"
                          alt={translations["Telephone"][lang]}
                          src={`${
                            $project.data.imageFolders.icons
                          }${translations["Telephone"][lang]
                            .toLowerCase()
                            .replaceAll(" ", "-")}.png?auto=format`}
                        />
                        <b><Translate text="Telephone" /></b>
                      </td>
                      <td
                        >{reviewOperator.data.support[$project.settings.market]
                          .phone}</td
                      >
                    </tr>
                  {/if}
                  {#if reviewOperator.url}
                    <tr>
                      <td class="icon">
                        <img
                          loading="lazy"
                          alt={translations["Website"][lang]}
                          src={`${
                            $project.data.imageFolders.icons
                          }${translations["Website"][lang]
                            .toLowerCase()
                            .replaceAll(" ", "-")}.png?auto=format`}
                        />
                        <b><Translate text="Website" /></b>
                      </td>
                      <td>
                        {#if reviewOperator.rating[$project.key][$project.settings.market]["closed"] || !reviewOperator.rating[$project.key][$project.settings.market]["license"]}
                          {reviewOperator.url.replace("www.", "")}
                        {:else}
                          <a
                            href={`https://${reviewOperator.url}`}
                            target="_blank"
                            >{reviewOperator.url.replace("www.", "")}</a
                          >
                        {/if}
                      </td>
                    </tr>
                  {/if}
                </tbody>
              </table>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
