<script>
  import PreviewContent from "$lib/admin/components/content/PreviewContent.svelte";
  import {
    projectToplists,
    orgOperators,
    orgCasinoGames,
    orgGameProviders,
    postData,
    postUsers,
    project,
    lotteryResults
  } from "$lib/services/store";

  export let data;

  let { match, post, session } = data;
  $: ({ match, post, session } = data);

  // Reactively update the data stores. This any store that needs to
  // be updated on route change should be added here
  $: {
    postUsers.set(data.usersData);
    postData.set(data.post);
    projectToplists.set(data.toplistData);
    orgOperators.set(data.operatorData);
    orgCasinoGames.set(data.casinoGamesData);
    orgGameProviders.set(data.gameProvidersData);
    lotteryResults.set(data.lotteryResultsData)
  }
</script>

<svelte:head>
  <title>{
    post.seo.page_title
    ? post.seo.page_title
    : post.title
    ? `${post.title} - ${$project.name}`
    : "No page title set - " + post.project_key.toUpperCase()}</title>
  <meta
    name="description"
    content={
      (post.seo.meta_description && post.parent_id === $project.data.categoryPages.news.id)
      ? post.seo.meta_description.substring(0, 149)
      : post.seo.meta_description
      ? post.seo.meta_description
      : post.content.find(block => block.blockType === 'Intro')
      ? post.content.find(block => block.blockType === 'Intro')?.props.content.replace(/(<([^>]+)>)/gi, "").substring(0, 149)
      : post.content.find(block => block.blockType === 'Content')
      ? post.content.find(block => block.blockType === 'Content')?.props.content.replace(/(<([^>]+)>)/gi, "").substring(0, 149)
      : "No meta description set"}/>
</svelte:head>

<PreviewContent {post} {session} />